<template>
  <div class="flex flex-col justify-center w-full mx-auto items-center sm:flex sm:flex-col sm:container sm:mx-auto sm:h-auto sm:items-center sm:justify-center sm:w-full">
        <div class="sm:flex">
            <h1 class="circular-font text-4xl sm:text-4xl dark:text-white">About Me</h1>
        </div>
        <p class="py-10 text-xl sm:text-xl text-gray-500 dark:text-gray-200">Information to contact me is in my resume above!</p> 
        <p class="text-base sm:text-lg text-black dark:text-gray-300">I'm a pre-med student turned tech-enthusiast who loves wearing more hats than can fit on his head.
            If I could have a degree for learning, I would. 
            It gives me so much energy to learn something new and be able to apply it. 
        </p> <br>
        <p class="text-base sm:text-lg text-black dark:text-gray-300">
            In my professional time, I've slowly focused in on the parts of the web development field that bring me the most enjoyment. 
            I've gained a lot of experience doing things like project managing, designing graphics, redesigning features, identifying user needs, creating intuitive experiences and developing functional prototypes. 
            I keep my sights aligned down that path and thats how I became a strong Individual Contributor as well as impactful Lead Engineer.
        </p> <br>
        <p class="text-base sm:text-lg text-black dark:text-gray-300">
            Because I love diversity in my creations, I tend to like working for a company that deals with multiple clients in different categories i.e.: Finance, Logistics, Entertainment, Food, Content Sharing, CRMs, Warehouse Management, Services (just to name a few).
            This gives me the best opportunity to create designs to effectively solve a business' need!     
        </p> <br>
        <p class="text-base sm:text-lg text-black dark:text-gray-300">
            In my spare time, I have my home office set up as a record studio and I play 7 instruments, so I love playing along and covering my favorite songs. I also teach software engineering and web design to keep my skills sharp, continue learning and to pay forward the amazing opportunities that I've received while working in the field.
            I also like to study machine learning and AI development because I think one day we can utilize it in the tech sphere for more than just improving engagement, but additionally to help innovate in more engaging and intuitive evolutions in how we utilize technology to improve our society.
        </p> <br>
        <p class="text-base sm:text-lg text-black dark:text-gray-300">
            In short, I'm just a software engineer seeking an opportunity to use my capabilitites to help revolutionize the tech world. We can discuss the revolution over a cup of coffee; isn't that how most revolutions start anyway?
        </p> 
    </div>
</template>

<script>
export default {
}
</script>

<style>
    .circular-font{
        font-family: 'Circular Std';
    }
</style>
