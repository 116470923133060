<template>
<!-- <Navbar /> -->
  <h1> PAGE NOT FOUND </h1>
</template>

<script>
//import Navbar from "@/components/Navbar"
export default {
    components: {
    }
}
</script>

<style scoped>

</style>